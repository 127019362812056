import React from 'react';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { SelectChangeEvent } from '@mui/material/Select';

interface UploadFormProps {
  projectName: string;
  experiment: string;
  genome: string;
  handleProjectNameChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleExperimentChange: (event: SelectChangeEvent) => void;
  handleGenomeChange: (event: SelectChangeEvent) => void;
  clearError: (field: string) => void;
  errors: { projectName?: string, experiment?: string, genome?: string }
}

const UploadForm: React.FC<UploadFormProps> = ({
  projectName,
  experiment,
  genome,
  handleProjectNameChange,
  handleExperimentChange,
  handleGenomeChange,
  clearError,
  errors
}) => {
  
  const handleProjectNameInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleProjectNameChange(event);
    clearError('projectName');
  };

  const handleExperimentSelectChange = (event: SelectChangeEvent) => {
    handleExperimentChange(event);
    clearError('experiment');
  };

  const handleGenomeSelectChange = (event: SelectChangeEvent) => {
    handleGenomeChange(event);
    clearError('genome');
  };

  return (
    <div className="grid grid-cols-3 gap-4 mb-4">
      <div className="relative">
        <TextField
          id="projectName"
          label="Project Name"
          value={projectName}
          onChange={handleProjectNameInputChange}
          variant="outlined"
          fullWidth
          sx={{
            '& .MuiOutlinedInput-root': {
              '&.Mui-focused fieldset': {
                borderColor: 'orange',
              },
            },
            '& .MuiInputLabel-root': {
              '&.Mui-focused': {
                color: 'orange',
              },
            },
          }}
        />
        {errors.projectName && <p className="text-red-600">{errors.projectName}</p>}
      </div>

      <div className="relative">
        <FormControl fullWidth variant="outlined" className="peer" sx={{
          '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
              borderColor: 'orange',
            },
          },
          '& .MuiInputLabel-root': {
            '&.Mui-focused': {
              color: 'orange',
            },
          },
        }}>
          <InputLabel className="text-gray-600">Experiment</InputLabel>
          <Select
            id="experiment"
            value={experiment}
            onChange={handleExperimentSelectChange}
            label="Experiment"
          >
            <MenuItem value="" disabled hidden>
              Select Experiment
            </MenuItem>
            <MenuItem value="RNAseq" className="px-4 py-2">
              RNAseq
            </MenuItem>
            <MenuItem value="BRBseq" className="px-4 py-2">
              BRBseq 
            </MenuItem>
            {/* <MenuItem value="Mass-spectrometry" className="px-4 py-2">
              Mass-spectrometry 
            </MenuItem> */}
          </Select>
        </FormControl>
        {errors.experiment && <p className="text-red-600">{errors.experiment}</p>}
      </div>

      <div className="relative">
        <FormControl fullWidth variant="outlined" className="peer" sx={{
          '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
              borderColor: 'orange',
            },
          },
          '& .MuiInputLabel-root': {
            '&.Mui-focused': {
              color: 'orange',
            },
          },
        }}>
          <InputLabel className="text-gray-600">Genome</InputLabel>
          <Select
            id="genome"
            value={genome}
            onChange={handleGenomeSelectChange}
            label="Genome"
          >
            <MenuItem value="" disabled hidden>
              Select Genome
            </MenuItem>
            <MenuItem value="hg38" className="px-4 py-2">
              hg38
            </MenuItem>
            <MenuItem value="hg19" className="px-4 py-2">
              hg19
            </MenuItem>
            <MenuItem value="mm10" className="px-4 py-2">
              mm10
            </MenuItem>
            <MenuItem value="mm9" className="px-4 py-2">
              mm9
            </MenuItem>
          </Select>
        </FormControl>
        {errors.genome && <p className="text-red-600">{errors.genome}</p>}
      </div>
    </div>
  );
};

export default UploadForm;
