import React, { useState, useEffect } from 'react';
import { getProfileApi, getProfilePictureApi } from '../../services/ProfileApi';
import { Profile } from '../../types/profile';
import default_image from '../../assets/profile_pic.jpg';
import ProfilePicture from './ProfilePicture';
import PersonalInfo from './PersonalInfo';
import MonthlyUsage from './MonthlyUsage';
import RemoveAccount from './RemoveAccount';
import UpdateProfileDialog from './UpdateProfileDialog';
import Pricing from './Pricing';
import { ClipLoader } from 'react-spinners';
import { GetSubscription, GetUserSubscription } from '../../services/subscriptionApi';

interface Subscription {
  id: string;
  name: string;
  price: string;
  currency_code: string;
  features: { [key: string]: string };
}

interface UserSubscription {
  id: string;
  status: string;
  item_price_id: string;
  item_price_name: string;
  item_price_amount: number;
  next_billing_at: number;
  
}

const Account: React.FC = () => {
  const [profileImage, setProfileImage] = useState<string>('');
  const [profile, setProfile] = useState<Profile>({
    email: '',
    first_name: '',
    last_name: '',
    position: '',
    institution: '',
    location: '',
    phone_number: '',
    total_data: 0,
    num_projects:0,
    is_free_trial:false,
  });
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [subscriptions, setSubscriptions] = useState<Subscription[]>([]);
  const [userSubscription, setUserSubscription] = useState<UserSubscription | null>(null);

  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        const response = await getProfileApi();
        setProfile(response.data);
      } catch (error) {
        console.error('Error fetching profile data:', error);
      }
    };

    const fetchProfilePicture = async () => {
      try {
        const response = await getProfilePictureApi();
        const imageUrl = URL.createObjectURL(response.data);
        setProfileImage(imageUrl);
      } catch (error) {
        console.error('Error fetching profile picture:', error);
        setProfileImage(default_image);
      }
    };

    const fetchSubscriptions = async () => {
      try {
        const [subscriptionResponse, userSubscriptionResponse] = await Promise.all([
          GetSubscription(),
          GetUserSubscription(),
        ]);

        const subscriptions: Subscription[] = subscriptionResponse.data;
        const userSubscription: UserSubscription | null = userSubscriptionResponse.data;

        setSubscriptions(subscriptions);
        setUserSubscription(userSubscription);
      } catch (error) {
        console.error('Error fetching subscriptions:', error);
      }
    };

    const fetchData = async () => {
      await Promise.all([fetchProfileData(), fetchProfilePicture(), fetchSubscriptions()]);
      setIsLoading(false);
    };

    fetchData();
  }, []);

  const handleProfileUpdate = (updatedProfile: Profile) => {
    setProfile(updatedProfile);
  };

  const handleOpenDialog = () => {
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <ClipLoader size={100} color={"#123abc"} loading={isLoading} />
      </div>
    );
  }

  return (
    <div className="w-full p-6 mx-auto bg-white rounded-lg shadow-md max-w-8xl">
      <ProfilePicture />
      <PersonalInfo profile={profile} onOpenDialog={handleOpenDialog} />
      <MonthlyUsage profile={profile} userSubscription={userSubscription} />
      <Pricing 
  subscriptions={subscriptions} 
  userSubscription={userSubscription} 
  isFreeTrial={profile.is_free_trial} 
/>

      <RemoveAccount />
      <UpdateProfileDialog
        open={isDialogOpen}
        onClose={handleCloseDialog}
        profile={profile}
        onProfileUpdate={handleProfileUpdate}
      />
    </div>
  );
};

export default Account;
