import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import SamplesToBeConfigured from './SamplesToBeConfigured';
import NameAndDefineGroups from './NameAndDefineGroups';
import ConfiguredSamples from './ConfiguredSamples';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Swal from 'sweetalert2';
import { SubmitGroups } from '../../services/ConfigApi';
import ConfigureTutorialModal from './ConfigureTutorialModal';
interface Sample {
  fileName: string;
  sampleName: string;
  groupName?: string;
  color?: string;
  associatedFiles?: string[]; // Only for FASTQ data
}

interface Params extends Record<string, string | undefined> {
  projectname?: string;
}
const Configure: React.FC = () => {
  const { projectname } = useParams<Params>();
  const navigate = useNavigate(); // Use navigate for redirection

  const initialModalState = localStorage.getItem('showTutorial') !== 'false';
  const [isModalOpen, setIsModalOpen] = useState<boolean>(initialModalState);
  const [colorResetKey, setColorResetKey] = useState(0); // Key to trigger color reset
  const [selectedSamples, setSelectedSamples] = useState<Sample[]>([]);
  const [configuredSamples, setConfiguredSamples] = useState<Sample[]>([]);
  const [samples, setSamples] = useState<Sample[]>([]);
  const [error, setError] = useState<string | null>(null); // Error state
  const [selectedConfiguredSamples, setSelectedConfiguredSamples] = useState<Sample[]>([]);
  useEffect(() => {
    if (projectname) {
      const storedSamples = localStorage.getItem(`${projectname}-samples`);
      const storedConfiguredSamples = localStorage.getItem(`${projectname}-configured-samples`);
      if (storedSamples && storedSamples !== '[]') {
        setSamples(JSON.parse(storedSamples));
      }

      if (storedConfiguredSamples && storedConfiguredSamples !== '[]') {
        setConfiguredSamples(JSON.parse(storedConfiguredSamples));
      }
    }
  }, [projectname]);

  useEffect(() => {
    if (projectname) {
      localStorage.setItem(`${projectname}-samples`, JSON.stringify(samples));
      localStorage.setItem(`${projectname}-configured-samples`, JSON.stringify(configuredSamples));
    }
  }, [samples, configuredSamples, projectname]);

  const handleSaveConfiguration = async () => {
    const uniqueGroups = Array.from(new Set(configuredSamples.map(sample => sample.groupName).filter(Boolean)));

    if (uniqueGroups.length < 2) {
        setError('Please configure at least two groups before proceeding.');
        return;
    }

    setError(null);

    if (projectname) {
        const formattedData: string[][] = [];

        configuredSamples.forEach(sample => {
            if (sample.associatedFiles && sample.associatedFiles.length > 0) {
                sample.associatedFiles.forEach(associatedFile => {
                    formattedData.push([
                        associatedFile,
                        sample.sampleName,
                        sample.groupName || '',
                        sample.color || '',
                        '', // Blank field 1
                        ''  // Blank field 2
                    ]);
                });
            } else {
                formattedData.push([
                    sample.fileName,
                    sample.sampleName,
                    sample.groupName || '',
                    sample.color || '',
                    '', // Blank field 1
                    ''  // Blank field 2
                ]);
            }
        });

        try {
            const response = await SubmitGroups(projectname, JSON.stringify(formattedData));

                const uniqueGroupsData = uniqueGroups.map(groupName => {
                    const sample = configuredSamples.find(sample => sample.groupName === groupName);
                    return { groupName: sample?.groupName, color: sample?.color };
                });

                localStorage.setItem(`${projectname}-configured-groups`, JSON.stringify(uniqueGroupsData));
                navigate(`/compare/${projectname}`);
            
        } catch (error) {
            console.error('Error saving configuration:', error);
            Swal.fire({
                title: 'Error!',
                text: 'There was an error saving the configuration.',
                icon: 'error',
                confirmButtonText: 'OK'
            });
        }
    }
};
const handleResetColorOptions = () => {
  setColorResetKey(prevKey => prevKey + 1); // Trigger reset in NameAndDefineGroups
};
  const handleDiscard = () => {
    if (projectname) {
      const allConfiguredSamples = [...configuredSamples, ...samples];
      localStorage.setItem(`${projectname}-samples`, JSON.stringify(allConfiguredSamples));
      localStorage.removeItem(`${projectname}-configured-samples`);

      setSamples(allConfiguredSamples);
      setConfiguredSamples([]);
      handleResetColorOptions();
    }
  };


  const handleMoveToExistingGroup = (selectedSamples: Sample[]) => {
    // Get unique group names from configured samples
    const groupNames = Array.from(new Set(
      configuredSamples
        .filter(sample => sample.groupName)
        .map(sample => sample.groupName)
    )) as string[];
  
    // Show a selection popup to choose an existing group
    Swal.fire({
      title: 'Move to Existing Group',
      input: 'select',
      inputOptions: groupNames.reduce((options, groupName) => {
        options[groupName] = groupName; // Populate the select options with group names
        return options;
      }, {} as Record<string, string>),
      inputPlaceholder: 'Select a group',
      showCancelButton: true,
    }).then(result => {
      if (result.isConfirmed && result.value) {
        const selectedGroupName = result.value;
        const groupColor = configuredSamples.find(sample => sample.groupName === selectedGroupName)?.color;
  
        if (selectedGroupName && groupColor) {
          // Iterate over all selected samples and add them to the existing group
          const updatedConfiguredSamples = [
            ...configuredSamples,
            ...selectedSamples.map(sample => ({
              ...sample,
              groupName: selectedGroupName,
              color: groupColor
            }))
          ];
  
          // Remove the moved samples from the unconfigured list
          const remainingSamples = samples.filter(s => !selectedSamples.includes(s));
  
          // Update state and localStorage
          setConfiguredSamples(updatedConfiguredSamples);
          setSamples(remainingSamples);
  
          localStorage.setItem(`${projectname}-configured-samples`, JSON.stringify(updatedConfiguredSamples));
          localStorage.setItem(`${projectname}-samples`, JSON.stringify(remainingSamples));
        }
      }
    });
  };
  
  const handleRemoveFromNonConfigured = (samplesToRemove: Sample[]) => {
    setSamples(prevSamples => {
      const updatedSamples = prevSamples.filter(sample => !samplesToRemove.includes(sample));
      localStorage.setItem(`${projectname}-samples`, JSON.stringify(updatedSamples)); // Update localStorage
      return updatedSamples;
    });
    setSelectedSamples([]); // Clear selection after removal
  };
  
  const handleRemoveFromConfiguredSamples = (samplesToMove: Sample[]) => {
    setConfiguredSamples(prev => {
      const updatedConfiguredSamples = prev.filter(sample => !samplesToMove.includes(sample));
      localStorage.setItem(`${projectname}-configured-samples`, JSON.stringify(updatedConfiguredSamples)); // Update localStorage
      return updatedConfiguredSamples;
    });
    
    setSamples(prev => {
      const updatedSamples = [...prev, ...samplesToMove.map(sample => ({ ...sample, groupName: undefined, color: undefined }))];
      localStorage.setItem(`${projectname}-samples`, JSON.stringify(updatedSamples)); // Update localStorage
      return updatedSamples;
    });
    
    setSelectedSamples([]); // Clear selection after the action
  };
  
    
  return (
    <div className="relative min-h-screen p-6 bg-white">
      <ConfigureTutorialModal 
      isModalOpen={isModalOpen}
      setIsModalOpen={setIsModalOpen} />
      <div className="flex items-center justify-between mb-4">
        <h1 onClick={() => navigate('/')} className="text-2xl font-semibold cursor-pointer">
          <ArrowBackIcon className="mb-1 mr-2" />Configure Project
        </h1>
        {selectedSamples.length > 0 && samples.some(sample => selectedSamples.includes(sample)) && (
      <>
      <div className="flex space-x-2">
        <button
          className="w-56 px-2 py-3 text-white bg-teal-700 rounded-md hover:bg-teal-800"
          onClick={() => handleMoveToExistingGroup(selectedSamples)}
        >
          Move to Existing Group
        </button>
        <button
          className="w-40 px-2 py-3 text-white bg-orange-600 rounded-md hover:bg-orange-700"
          onClick={() => handleRemoveFromNonConfigured(selectedSamples)}
        >
          Remove
        </button>
      </div>
    </>
    
    )}
    
    {selectedConfiguredSamples.length > 0 && configuredSamples.some(sample => selectedConfiguredSamples.includes(sample)) && (
     <> <button
        className="px-2 py-3 mt-2 text-white bg-orange-600 rounded-md w-72 hover:bg-orange-700"
        onClick={() => handleRemoveFromConfiguredSamples(selectedConfiguredSamples)}
      >
        Remove (Move to Non-Configured)
      </button></>
    )}
        <button 
  className="px-4 py-2 text-sm font-semibold text-blue-700 transition-all bg-white border border-blue-500 rounded-lg hover:bg-blue-50" 
  onClick={handleDiscard}
>
  Reset
</button>

      </div>
      <div className="grid grid-cols-12 gap-2">
        <div className="col-span-4">
          <SamplesToBeConfigured
            selectedSamples={selectedSamples}
            setSelectedSamples={setSelectedSamples}
            samples={samples}
            setSamples={setSamples}
            configuredSamples={configuredSamples}
            setConfiguredSamples={setConfiguredSamples}
            handleMoveToExistingGroup={handleMoveToExistingGroup}
          />
        </div>
        <div className="col-span-8">
          <NameAndDefineGroups
            selectedSamples={selectedSamples}
            setConfiguredSamples={setConfiguredSamples}
            setSelectedSamples={setSelectedSamples}
            setSamples={setSamples}
            samples={samples}
            configuredSamples={configuredSamples}
            colorResetKey={colorResetKey}
          />
          <ConfiguredSamples
            selectedConfiguredSamples={selectedConfiguredSamples}
            setselectedConfiguredSamples={setSelectedConfiguredSamples}
            configuredSamples={configuredSamples}
            setConfiguredSamples={setConfiguredSamples}
            setSamples={setSamples}
          />
        </div>
        <div className="flex justify-between col-span-12 mt-4">
  <button
    className="p-3 font-semibold text-black rounded"
    style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minWidth: '170px' }}
    onClick={() => navigate('/')}
  >
    <ArrowBackIcon className="mr-2" />Go back
  </button>
  <div className="flex flex-col items-end text-center"> {/* Align the button and error to the right */}
    <button
      className="p-3 font-semibold text-white bg-teal-700 rounded"
      style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minWidth: '170px' }}
      onClick={handleSaveConfiguration}
    >
      Save Configuration
    </button>
    {error && (
      <div className="mt-2 text-sm text-right text-red-600"> {/* Align the error message to the right */}
        {error}
      </div>
    )}
  </div>
</div>

      </div>
    </div>
  );
};

export default Configure;
