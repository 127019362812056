import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import { getFromGeoApi, SubmitFtp } from '../../../services/projectApi';
import ModalFileUploadSection from './ModalFileUploadSection';
import ModalAdditionalInputs from './ModalAdditionalInputs';
import { useNavigate } from 'react-router-dom';
import { GetUserSubscription } from '../../../services/subscriptionApi';
import { getProfileApi } from '../../../services/ProfileApi';
import UploadProgress from '../../upload/UploadProgress';
import { useProjectRefresh } from '../../../contexts/ProjectRefreshContext';
import {
  uploadFiles,
  handleFileDrop,
  uploadUrls,
} from '../../upload/uploadFunctions';
import { getSubscriptionLimits } from '../../../utils/subscriptionLimits';
import { ClipLoader } from 'react-spinners';
interface UploadModalProps {
  isOpen: boolean;
  onClose: () => void;
  projectName: string;
}
type SelectedURLfile = {
  filename: string;
  filesize: number;
  url: string;
};
type FtpDetails = {
  hostname: string;
  sftp_username: string;
  sftp_password: string;
  sftp_remote_path: string;
  file_name: string;
  file_size: number;
};
const UploadModal: React.FC<UploadModalProps> = ({
  isOpen,
  onClose,
  projectName,
}) => {
  const [experiment, setExperiment] = useState<string>('');
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [uploading, setUploading] = useState<boolean>(false);
  const [progress, setProgress] = useState<number>(0);
  const [geoCode, setGeoCode] = useState<string>('');
  const [geoCodes, setGeoCodes] = useState<string[]>([]);
  const [fileUrl, setFileUrl] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<{
    projectName?: string;
    experiment?: string;
    genome?: string;
    selectedFilesOrURLs?: string;
  }>({});
  const [SelectedURLfile, setSelectedURLfile] = useState<SelectedURLfile[]>([]);
  const [ftpDetails, setFtpDetails] = useState<FtpDetails[]>([]); // New state for SFTP details
  const navigate = useNavigate();
  const [urlUploading, setUrlUploading] = useState<boolean>(false);
  const [buttonText, setButtonText] = useState<string>('Upload');
  const triggerRefresh = useProjectRefresh();
  const clearError = (field: string) => {
    setErrors((prevErrors) => ({
      ...prevErrors,
      [field]: undefined,
    }));
  };

  const checkStorageLimit = async (
    selectedFiles: File[],
    SelectedURLfile: SelectedURLfile[],
    ftpDetails: FtpDetails[],
  ): Promise<boolean> => {
    setLoading(true);
    const totalSelectedFileSizeMB = Math.round(
      selectedFiles.reduce((acc, file) => acc + file.size, 0) / (1024 * 1024), // Converting to MB
    );
    const totalFileSizeMB = totalSelectedFileSizeMB;
    const totalURLFileSizeMB =
      SelectedURLfile.reduce((acc, file) => acc + file.filesize, 0) /
      (1024 * 1024);
    const totalFtpFileSizeMB =
      ftpDetails.reduce((acc, ftp) => acc + ftp.file_size, 0) / (1024 * 1024);
    const combinedTotalFileSizeMB =
      totalFileSizeMB + totalURLFileSizeMB + totalFtpFileSizeMB;

    const [subscriptionResponse, profileResponse] = await Promise.all([
      GetUserSubscription(),
      getProfileApi(),
    ]);
    const subscription = subscriptionResponse.data;
    const profile = profileResponse.data;

    const limits = getSubscriptionLimits(
      subscription?.item_price_name || 'Free',
    ); // Fetch limits from the utility
    const maxStorageMB = limits.maxDataGB * 1024; // Convert GB to MB

    const totalDataMB = profile.total_data;

    if (totalDataMB + combinedTotalFileSizeMB > maxStorageMB) {
      const exceededAmountMB =
        totalDataMB + combinedTotalFileSizeMB - maxStorageMB;
      Swal.fire({
        title: 'Storage Limit Exceeded',
        text: `You have exceeded your data usage by ${exceededAmountMB} MB. Please upgrade your plan to proceed.`,
        icon: 'error',
        showCancelButton: true,
        confirmButtonText: 'Upgrade Plan',
        cancelButtonText: 'No',
      }).then((result) => {
        if (result.isConfirmed) {
          navigate('/account');
        }
      });
      setLoading(false);
      return false; // Exceeded storage
    }
    setLoading(false);
    return true; // Within storage limit
  };

  const handleFileSelect = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (
      SelectedURLfile.length > 0 ||
      geoCodes.length > 0 ||
      ftpDetails.length > 0
    ) {
      setErrors({
        selectedFilesOrURLs:
          'You can only upload one type: File, URL, GEO code, or FTP.',
      });
      return; // Prevent submission
    }

    const files = event.target.files;
    if (files) {
      const newFiles = Array.from(files);

      const pattern = /^[a-zA-Z0-9_.-]+$/;

      const invalidFiles = newFiles.filter((file) => !pattern.test(file.name));
      if (invalidFiles.length > 0) {
        Swal.fire({
          icon: 'error',
          title: 'Invalid File Names',
          text: `The following files have invalid names: ${invalidFiles.map((file) => file.name).join(', ')}. Only alphanumerical characters, '-', '.' and '_' are allowed.`,
          width: '350px',
          padding: '1em',
          position: 'top-end',
        });
        return;
      }

      const newSelectedFiles = [...selectedFiles, ...newFiles];
      const withinLimit = await checkStorageLimit(
        selectedFiles,
        SelectedURLfile,
        ftpDetails,
      );
      if (!withinLimit) {
        return;
      }

      setSelectedFiles(newSelectedFiles);
      clearError('selectedFilesOrURLs');
    }
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    const newErrors: {
      projectName?: string;
      experiment?: string;
      genome?: string;
      selectedFilesOrURLs?: string;
    } = {};
    if (!projectName) newErrors.projectName = 'Project name is required';
    if (!experiment) newErrors.experiment = 'Experiment name is required';
    if (
      selectedFiles.length === 0 &&
      SelectedURLfile.length === 0 &&
      geoCodes.length === 0 &&
      ftpDetails.length === 0
    ) {
      newErrors.selectedFilesOrURLs =
        'Either rawdata file, GEO code, or Link must be uploaded before proceeding';
    }

    setErrors(newErrors);

    const withinLimit = await checkStorageLimit(
      selectedFiles,
      SelectedURLfile,
      ftpDetails,
    );

    if (!withinLimit) {
      return;
    }

    try {
      setLoading(true);
      setButtonText('Uploading');
      setUploading(true);

      const uploadPromises = [];

      if (geoCodes.length > 0) {
        uploadPromises.push(
          getFromGeoApi(geoCodes, projectName).then((response) => {
            if (response.status !== 200 && response.status !== 202) {
              throw new Error(
                response.data?.detail ||
                  `Failed to upload GEO data for code: ${geoCodes}`,
              );
            }
          }),
        );
      }

      if (SelectedURLfile.length > 0) {
        setUrlUploading(true);
        const urlUploadPromise = uploadUrls(projectName, SelectedURLfile)
          .then((result) => {
            if (!result.success) {
              throw new Error(result.message);
            }
          })
          .finally(() => {
            setUrlUploading(false);
          });

        uploadPromises.push(urlUploadPromise);
      }

      if (selectedFiles.length > 0) {
        const fileUploadPromise = uploadFiles(
          projectName,
          experiment,
          selectedFiles,
          setUploading,
          setProgress,
        ).then((result) => {
          if (!result.success) {
            throw new Error(result.message);
          }
        });

        uploadPromises.push(fileUploadPromise);
      }

      if (ftpDetails.length > 0) {
        const combinedFtpFileSizeMB =
          ftpDetails.reduce((acc, ftp) => acc + ftp.file_size, 0) /
          (1024 * 1024);
        uploadPromises.push(
          SubmitFtp(ftpDetails, projectName, combinedFtpFileSizeMB).then(
            (response) => {
              if (response.status !== 202) {
                throw new Error(
                  response.data?.detail || 'Failed to upload SFTP files.',
                );
              }
            },
          ),
        );
      }

      const results = await Promise.allSettled(uploadPromises);
      const failedUploads = results.filter(
        (result) => result.status === 'rejected',
      );

      if (failedUploads.length > 0) {
        console.log('Failed uploads:', failedUploads);
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: `Some uploads failed: ${failedUploads.map((result) => (result as any).reason.message).join(', ')}`,
        });
      } else {
        Swal.fire({
          title: 'Upload Complete',
          text: 'Your files have been uploaded! Would you like to go to the projects page or Stay?',
          icon: 'info',
          showCancelButton: true,
          confirmButtonText: 'Go to Projects',
          cancelButtonText: 'Stay',
        }).then((result) => {
          if (result.isConfirmed) {
            navigate('/'); // Navigate to projects page
          }
        });

        triggerRefresh();
        setButtonText('Go Configure');
      }
    } catch (error) {
      console.error('Error during project creation or upload:', error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'An error occurred during the project creation or upload process.',
      });
    } finally {
      setLoading(false);
      setUploading(false);
      setUrlUploading(false);
    }
  };

  useEffect(() => {
    const dropZone = document.getElementById('drop-zone');

    const handleDrop = (event: DragEvent) => {
      event.preventDefault();
      handleFileDrop(event, setSelectedFiles);
    };

    if (dropZone) {
      dropZone.addEventListener('dragover', (event) => {
        event.preventDefault();
      });

      dropZone.addEventListener('drop', handleDrop);
    }

    return () => {
      if (dropZone) {
        dropZone.removeEventListener('dragover', (event) => {
          event.preventDefault();
        });

        dropZone.removeEventListener('drop', handleDrop);
      }
    };
  }, []);
  return (
    <div className="fixed inset-0 z-50 overflow-y-auto">
      <div className="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
        <div
          onClick={onClose}
          className="fixed inset-0 transition-opacity"
          aria-hidden="true"
        >
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="inline-block overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full">
            <div className="px-4 pt-5 pb-4 bg-white sm:p-6 sm:pb-4">
              <h3 className="mb-2 text-lg font-medium leading-6 text-gray-900">
                Upload Files for {projectName}
              </h3>
              <p className="mb-2 text-sm text-gray-500">
                Upload files and provide additional required information for the
                project.
              </p>
              <div id="drop-zone">
                {loading ? (
                  <div className="flex items-center justify-center">
                    <ClipLoader size={50} color={'#123abc'} loading={loading} />
                  </div>
                ) : (
                  <ModalFileUploadSection
                    uploading={uploading}
                    handleFileSelect={handleFileSelect}
                  />
                )}
              </div>
              <ModalAdditionalInputs
                uploading={uploading}
                geoCode={geoCode}
                setGeoCode={setGeoCode}
                geoCodes={geoCodes}
                setGeoCodes={setGeoCodes}
                fileUrl={fileUrl}
                setFileUrl={setFileUrl}
                selectedFiles={selectedFiles}
                setSelectedFiles={setSelectedFiles}
                SelectedURLfile={SelectedURLfile}
                setSelectedURLfile={setSelectedURLfile}
                ftpDetails={ftpDetails}
                setFtpDetails={setFtpDetails}
                clearError={clearError}
                setErrors={setErrors}
              />
            </div>
            {errors.selectedFilesOrURLs && (
              <p className="text-red-600">{errors.selectedFilesOrURLs}</p>
            )}
            <UploadProgress
              uploading={uploading || urlUploading || geoCodes.length > 0}
              progress={progress}
              fileType={
                urlUploading ? 'url' : geoCodes.length > 0 ? 'geocode' : 'file'
              }
            />
            <div className="px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
              <button
                type="submit"
                className={`px-4 py-2 text-white rounded ${uploading ? 'bg-gray-500' : 'bg-teal-700 hover:bg-teal-800'}`}
                disabled={uploading}
                onClick={(e) => {
                  if (buttonText === 'Go Configure') {
                    e.preventDefault();
                    navigate(`/configure/${projectName}`);
                  }
                }}
              >
                {buttonText}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default UploadModal;
