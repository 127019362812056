import React, { useState } from 'react';
import { Checkbox, Button } from '@mui/material';

interface Sample {
  fileName: string;
  sampleName: string;
  groupName?: string;
  color?: string;
  associatedFiles?: string[]; // Only for FASTQ data
}

interface ConfiguredSamplesProps {
  configuredSamples: Sample[];
  setConfiguredSamples: React.Dispatch<React.SetStateAction<Sample[]>>;
  setSamples: React.Dispatch<React.SetStateAction<Sample[]>>;
  selectedConfiguredSamples:Sample[];
  setselectedConfiguredSamples:React.Dispatch<React.SetStateAction<Sample[]>>;
}

const ConfiguredSamples: React.FC<ConfiguredSamplesProps> = ({ selectedConfiguredSamples,setselectedConfiguredSamples,configuredSamples, setConfiguredSamples, setSamples }) => {
  const [selectedGroup, setSelectedGroup] = useState<string | null>(null);
  // State for selected samples
  const [startIndex, setStartIndex] = useState(0);
  const groupsPerPage = 3;

  const filteredSamples = selectedGroup
    ? configuredSamples.filter(sample => sample.groupName === selectedGroup)
    : configuredSamples;

  const handleRemoveFromConfiguredSamples = (samplesToRemove: Sample[]) => {
    setConfiguredSamples(prev => prev.filter(sample => !samplesToRemove.includes(sample)));
    setSamples(prev => [...prev, ...samplesToRemove.map(sample => ({ ...sample, groupName: undefined, color: undefined }))]);
    setselectedConfiguredSamples([]); // Reset the selected samples after removal
  };

  const handleSelectSample = (sample: Sample) => {
    if (selectedConfiguredSamples.includes(sample)) {
      setselectedConfiguredSamples(prev => prev.filter(s => s !== sample));
    } else {
      setselectedConfiguredSamples(prev => [...prev, sample]);
    }
  };

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setselectedConfiguredSamples(filteredSamples);
    } else {
      setselectedConfiguredSamples([]);
    }
  };

  const isSelected = (sample: Sample) => selectedConfiguredSamples.includes(sample);

  const groups = configuredSamples.reduce((acc: { [key: string]: string }, sample) => {
    if (sample.groupName && sample.color && !acc[sample.groupName]) {
      acc[sample.groupName] = sample.color;
    }
    return acc;
  }, {});

  const groupEntries = Object.entries(groups);
  const visibleGroups = groupEntries.slice(startIndex, startIndex + groupsPerPage);

  const handlePrevious = () => {
    setStartIndex(prev => Math.max(prev - groupsPerPage, 0));
  };

  const handleNext = () => {
    setStartIndex(prev => Math.min(prev + groupsPerPage, groupEntries.length - groupsPerPage));
  };

  const toggleGroupSelection = (groupName: string) => {
    if (selectedGroup === groupName) {
      setSelectedGroup(null); // Deselect and show all groups
    } else {
      setSelectedGroup(groupName); // Select the clicked group
    }
  };

  const ArrowSvg = ({ direction }: { direction: 'left' | 'right' }) => (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d={direction === 'right' ? 'M8 5L16 12L8 19' : 'M16 19L8 12L16 5'}
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  return (
    <div className="pl-4">
      <h2 className="mb-2 text-xl font-semibold">Configured Samples</h2>
      <div className="relative flex items-center mb-4">
        <button
          onClick={handlePrevious}
          className={`w-10 h-10 flex items-center justify-center rounded-full transition ${startIndex > 0 ? 'bg-orange-500 text-white border-white border-0' : 'bg-white text-gray-500 border-gray-500 cursor-not-allowed border-2'}`}
          disabled={startIndex <= 0}
        >
          <ArrowSvg direction="left" />
        </button>
        <div className="grid flex-1 grid-cols-1 gap-4 mx-8 md:grid-cols-2 lg:grid-cols-3">
          {visibleGroups.length > 0 ? (
            visibleGroups.map(([groupName, color], index) => (
              <button
                key={index}
                className={`p-4 border rounded-lg ${selectedGroup === groupName ? 'border-4' : ''}`}
                style={{ borderColor: color }}
                onClick={() => toggleGroupSelection(groupName)}
              >
                <div className="flex items-center justify-between space-x-2">
                  <span className="font-bold text-gray-700">{groupName}</span>
                  <div className="rounded w-7 h-7" style={{ backgroundColor: color }}></div>
                </div>
              </button>
            ))
          ) : (
            ""
          )}
        </div>
        <button
          onClick={handleNext}
          className={`w-10 h-10 flex items-center justify-center rounded-full transition ${startIndex + groupsPerPage < groupEntries.length ? 'bg-orange-500 text-white border-white border-0' : 'bg-white text-gray-500 border-gray-500 cursor-not-allowed border-2'}`}
          disabled={startIndex + groupsPerPage >= groupEntries.length}
        >
          <ArrowSvg direction="right" />
        </button>
      </div>
      <div className="p-4 bg-white border rounded-lg">
        <div style={{ maxHeight: '342px', overflowY: 'auto' }}>
          <table className="min-w-full divide-y divide-gray-200">
          <thead className="sticky top-0 z-10 border-b border-black bg-gray-50">
  <tr>
    <th className="px-6 py-3">
      <Checkbox
        checked={selectedConfiguredSamples.length === filteredSamples.length && filteredSamples.length > 0}
        onChange={handleSelectAll}
      />
    </th>
    <th className="px-6 py-3 font-semibold tracking-wider text-left text-gray-700">
      File Name
    </th>
    <th className="px-6 py-3 font-semibold tracking-wider text-left text-gray-700">
      Sample Name
    </th>
    <th className="px-6 py-3 font-semibold tracking-wider text-left text-gray-700">
      Group
    </th>
    <th className="px-6 py-3 font-semibold tracking-wider text-left text-gray-700">
      Batch
    </th>
    <th className="px-6 py-3 font-semibold tracking-wider text-left text-gray-700">
      Action
    </th>
  </tr>
</thead>

            <tbody className="bg-white divide-y divide-gray-200">
              {filteredSamples.length > 0 ? (
                filteredSamples.map((sample, index) => (
                  <tr key={index} className="hover:bg-gray-100 ">
                    <td className="px-6 py-4 whitespace-nowrap">
                      <Checkbox
                        checked={isSelected(sample)}
                        onChange={() => handleSelectSample(sample)}
                      />
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {sample.fileName}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {sample.sampleName}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap" style={{ color: sample.color }}>
                      {sample.groupName}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      -
                    </td>
                    <td className="px-6 py-4 text-sm font-medium whitespace-nowrap">
                      <button 
                        className="text-red-600"
                        onClick={() => handleRemoveFromConfiguredSamples([sample])}
                      >
                        Remove
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={6} className="px-6 py-4 text-sm text-center text-gray-500 whitespace-nowrap">
                    No configured samples
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>

     
    </div>
  );
};

export default ConfiguredSamples;
